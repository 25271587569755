import React from "react"
import { Link } from "gatsby"
import "./footer.less"
const lang = {
  disclaimer: "Disclaimer",
  disclaimer1stParagraph:
    "In our Products, we use information models, research and data (including historical, estimated and simulated weather and agronomic data), and combine it with information provided by you, to generate the suggestions, moisture profile percentages, growth stages, anticipated transplant dates and other information we provide. The generated information and suggestions are estimates based on these inputs, and we cannot guarantee actual results. Our Products, models, data and suggestions may change over time. Individual results may vary, as weather, growing conditions and farming practices differ across growers, locations and years.",
  disclaimer2ndParagraph:
    "Our suggestions and the Products should not be used as a substitute for sound farming practices, including diligent field monitoring, or as a sole means for making farming, risk management or financial decisions. We want to help you make better decisions, but we are not acting as your agronomist, pest control advisor, financial advisor, insurance agent, commodity broker or agent. Consult those service professionals before making decisions.",
  disclaimer3rdParagraph:
    'The chart for this hybrid is displaying the soil moisture percentage range we maintained at our research centers in California and Florida. REMEMBER: Our trial was done with subsurface drip irrigation buried 8" to 12" deep with sensors reading moisture at 8"-10". Your results WILL vary and this information is NOT a recommendation. Have no fear, contact us or your local agronomist with any questions.',
}
const Footer = () => (
  <div id="footer-menu" className="home-page">
    <div id="footer-menu-container">
      <div className="footer-right">
        <div className="footer-sub-menu">
          <div className="footer-sub-menu-title">Company</div>
          <div className="footer-sub-menu-item">
            <Link to="/about">About</Link>
          </div>
          <div className="footer-sub-menu-item">
            <a href="/careers">Careers</a>
          </div>
          <div className="footer-sub-menu-item">
            <a href="https://medium.com/@sostenainc" target="_blank">
              Blog
            </a>
          </div>
          <div className="footer-sub-menu-item">
            <Link to="/contact">Contact</Link>
          </div>
        </div>
        <div className="footer-sub-menu">
          <div className="footer-sub-menu-title">Get Info</div>
          <div className="footer-sub-menu-item">
            <Link to="/why-produceplan">Why Produceplan</Link>
          </div>
          <div className="footer-sub-menu-item">
            <Link to="/for-growers-shippers">Growers/Shippers</Link>
          </div>
          <div className="footer-sub-menu-item">
            <Link to="/traceability">Traceability</Link>
          </div>
          <div className="footer-sub-menu-item">
            <Link to="/press-release">USDA Financing</Link>
          </div>
        </div>
        <div className="footer-sub-menu">
          <div className="footer-sub-menu-title">Integrations</div>
          <div className="footer-sub-menu-item">
            <Link to="/features/quickbooks-integration">Quickbooks</Link>
          </div>
          <div className="footer-sub-menu-item">
            <a
              href="https://calendly.com/produceplanco/founder-meeting-demo?month=2025-03"
              target="_blank"
            >
              EDI Integrations
            </a>
          </div>
          <div className="footer-sub-menu-item">
            <a
              href="https://calendly.com/produceplanco/founder-meeting-demo?month=2025-03"
              target="_blank"
            >
              Oracle & SAP Integrations
            </a>
          </div>
        </div>
        <div className="footer-cta">
          <a href="tel:+1-877-450-0047" className="footer-cta-button">
            CALL US @ +1-877-450-0047
          </a>
        </div>
      </div>
    </div>
    <div id="social">
      <div className="social-link">
        <a href="https://www.linkedin.com/company/produceplan/" target="_blank">
          <i className="fa fa-linkedin-square" aria-hidden="true"></i>
        </a>
      </div>
      <div className="social-link">
        <a href="https://twitter.com/sostenaco" target="_blank">
          <i className="fa fa-twitter-square" aria-hidden="true"></i>
        </a>
      </div>
      <div className="social-link">
        <a href="https://www.instagram.com/sostenaofficial/" target="_blank">
          <i className="fa fa-instagram" aria-hidden="true"></i>
        </a>
      </div>
    </div>
    <div id="marketing-stock">
      <Link to="/marketing-stock">Marketing Stock</Link>
    </div>
    <div id="roi-calculator">
      <a href="/roi-calculator" target="_blank">
        ROI Calculator
      </a>
    </div>
    <div id="warehouse-savings">
      <a href="/warehouse-savings-calculator" target="_blank">
        Warehouse Savings Calculator
      </a>
    </div>
    <div id="copyright">
      Copyright © {new Date().getFullYear()} Produceplan&trade;. All Rights
      Reserved. <br />
      A Sostena Corporation Product
      <br />
    </div>
    <div className="disclaimer">
      <div className="container">
        <p>{lang.disclaimer}</p>
        <p>{lang.disclaimer1stParagraph}</p>
        <p>{lang.disclaimer2ndParagraph}</p>
      </div>
    </div>
  </div>
)

export default Footer
